<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>会员标签</el-breadcrumb-item>
            <el-breadcrumb-item to="/tag_library">标签库</el-breadcrumb-item>
            <el-breadcrumb-item>新增自动标签</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 返回页头 -->
      <el-page-header @back="goBack" content="新增自动标签"></el-page-header>
      <el-row class="cont-form-box">
         <el-row class="condition">
            <el-form ref="form" :model="ruleForm" :rules="rules">
               <el-form-item label="标签名称" prop="tagName">
                  <el-input class="width-300 m-right-10" v-model="ruleForm.tagName" placeholder="标签名称" clearable :disabled="action === 'look'"></el-input>
               </el-form-item>
               <el-form-item label="标签颜色">
                  <el-color-picker v-model="ruleForm.bgcolor" :disabled="action === 'look'"></el-color-picker>
               </el-form-item>
               <el-form-item label="标签类型">
                  <el-select v-model="ruleForm.type" class="width-160" :placeholder="$t('msg.select')" :disabled="action === 'look'">
                     <el-option
                         v-for="item in tagTypes"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="标签条件" v-if="ruleForm.type === 'AUTO_Tag'">
                  <el-button @click="visibleCondition = true">管理</el-button>
               </el-form-item>
               <el-form-item label="标签备注">
                  <el-input class="width-300 m-right-10" v-model="ruleForm.remark" placeholder="标签备注" type="textarea" clearable :disabled="action === 'look'"></el-input>
               </el-form-item>
               <el-form-item>
                  <el-button class="bg-gradient" type="primary" round @click="handleSave" :disabled="action === 'look'">保存条件</el-button>
               </el-form-item>
            </el-form>
         </el-row>
      </el-row>
      <!-- 条件管理 -->
      <el-dialog class="condition-popup" title="条件管理" :visible.sync="visibleCondition" :close-on-click-modal="false" width="900px">
         <condition @condition="condition" @close="visibleCondition = false"/>
      </el-dialog>
   </section>
</template>
<script>
import condition from '@/components/local/conditionMan'
import { urlObj } from '@/api/interface'
import { mapState } from "vuex";
export default {
   data(){
      return{
         conditionList: [],
         levelList: [],
         tagTypes: [
            { label: '手动增加', value: 'DIY_Tag' },
            { label: '自动增加', value: 'AUTO_Tag' }
         ],
         ruleForm: {
            tagName: '',         // 标签名称
            bgcolor: '',
            propStr: '',
            touchStr: '',
            type: 'DIY_Tag',
            remark: '',
         },
         rules: {
            tagName: [{ required: true, message: '请输入标签名称', trigger: 'blur' }]
         },
         tableData: [],
         limit: 1,
         page: 1,
         total: 0,
         groupId: '',
         action: '',
         visibleCondition: false
      }
   },
   components: {
      condition
   },
   computed:{
      ...mapState(["hotelInfo"])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.action = this.$route.query.action
      if (this.action === 'edit' || this.action === 'look') this.echoTagGroup()
   },
   beforeDestroy() {
      sessionStorage.removeItem('tagInfo')
   },
   methods: {
      // 回显群组条件
      echoTagGroup(){
         const tagInfo = JSON.parse(sessionStorage.getItem('tagInfo'))
         this.groupId = tagInfo.id
         for (const k in tagInfo) k in this.ruleForm && (this.ruleForm[k] = tagInfo[k])
      },
      // 条件参数
      condition(propStr, touchStr) {
         this.ruleForm.propStr = propStr
         this.ruleForm.touchStr = touchStr
         this.visibleCondition = false
      },
      // 保存标签
      handleSave(){
         this.$refs.form.validate(valid => {
            if (!valid) return
            let url = urlObj.saveAutoTag
            let param = {
               hotelId: this.hotelInfo.id,
               companyId: this.hotelInfo.storeId,
               ...this.ruleForm
            }
            if (this.ruleForm.type === 'DIY_Tag') {
               delete param.propStr
               delete param.touchStr
            }
            this.action === 'edit' && (param.id = this.groupId)
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$router.go(-1)
                  this.$message({
                     showClose: true,
                     message: `${ this.action === 'add' ? '添加' : '编辑' }成功`,
                     type: 'success'
                  })
               }
            })
         })
      },
      // 返回上一页
      goBack(){
         this.$router.go(-1)
      },
   }
}
</script>
<style scoped lang="scss">
.cont{
   min-width: 1120px;
   ::v-deep .el-form-item__error{ margin-left: 77px }
}
</style>
